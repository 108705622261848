import { DynamicVariables } from '@affine/env/global';
import {
  createContext,
  type PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';

const DynamicVariablesContext = createContext<DynamicVariables | null>(null);

export const dynamicVariables = new DynamicVariables();

dynamicVariables.set({
  DNB_APP_BUILD_TYPE: BUILD_CONFIG.appBuildType,
  SENTRY_DSN: process.env.SENTRY_DSN,
  SENTRY_ENABLED: process.env.SENTRY_ENABLED === 'true' ? true : false,
  SENTRY_RELEASE: window.SENTRY_RELEASE?.id,
  SENTRY_FEEDBACK_ENABLED:
    process.env.SENTRY_FEEDBACK_ENABLED === 'true' ? true : false,
});

export const DynamicVariablesProvider = ({ children }: PropsWithChildren) => {
  const [variables, setVariables] = useState<DynamicVariables | null>(null);

  useEffect(() => {
    dynamicVariables
      .load()
      .then(() => {
        setVariables(dynamicVariables);
      })
      .catch(console.error);
  }, []);

  return (
    <DynamicVariablesContext.Provider value={variables}>
      {children}
    </DynamicVariablesContext.Provider>
  );
};

export const useDynamicVariables = () => {
  const context = useContext(DynamicVariablesContext);
  return context?.variables;
};
