import './setup';

import { dynamicVariables } from '@affine/core/utils';
import {
  feedbackIntegration,
  init,
  reactRouterV6BrowserTracingIntegration,
  replayIntegration,
  setTags,
} from '@sentry/react';
import { StrictMode, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import { App } from './app';

async function main() {
  await dynamicVariables.load();
  if (dynamicVariables.variables.SENTRY_ENABLED) {
    // https://docs.sentry.io/platforms/javascript/guides/react/#configure

    const integrations = [
      reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      replayIntegration(),
    ];

    if (dynamicVariables.variables.SENTRY_FEEDBACK_ENABLED) {
      integrations.push(
        feedbackIntegration({
          colorScheme: 'system',
        })
      );
    }

    init({
      dsn: dynamicVariables.variables.SENTRY_DSN,
      environment:
        dynamicVariables.variables.DNB_APP_BUILD_TYPE ?? 'development',
      release: dynamicVariables.variables.SENTRY_RELEASE,
      integrations,
      // Tracing
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
    setTags({
      appVersion: BUILD_CONFIG.appVersion,
      editorVersion: BUILD_CONFIG.editorVersion,
    });
  }

  mountApp();
}

function mountApp() {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const root = document.getElementById('app')!;
  createRoot(root).render(
    <StrictMode>
      <App />
    </StrictMode>
  );
}

try {
  main().catch(err => console.error('Failed to bootstrap app', err));
} catch (err) {
  console.error('Failed to bootstrap app', err);
}
