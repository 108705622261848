import { z } from 'zod';

import type { DynamicEnvOptions } from './types';

export const dynamicVariablesSchema = z.object({
  DNB_YANDEX_METRIC: z.string().or(z.number().optional()).optional(),
  DNB_REQUIRED_AUTH: z.boolean().optional(),
  DNB_APP_BUILD_TYPE: z.enum(['stable', 'canary', 'beta', 'internal']),
  SENTRY_DSN: z.string().optional(),
  SENTRY_ENABLED: z.boolean().optional(),
  SENTRY_RELEASE: z.string().optional(),
  SENTRY_FEEDBACK_ENABLED: z.boolean().optional(),
});

export type DynamicVariablesType = z.infer<typeof dynamicVariablesSchema>;

export const defaultVariables: DynamicVariablesType = {
  DNB_APP_BUILD_TYPE: 'canary',
  DNB_YANDEX_METRIC: '',
  DNB_REQUIRED_AUTH: false,
  SENTRY_ENABLED: false,
};

export class DynamicVariables {
  private _variables: DynamicVariablesType = defaultVariables;
  private _load: boolean = false;

  get variables() {
    return this._variables;
  }

  set = (variables: DynamicVariablesType) => {
    const mergeVariables = { ...this.variables, ...variables };
    dynamicVariablesSchema.parse(mergeVariables);
    this._variables = mergeVariables;
  };

  load = async (
    path: string = '/env.json',
    options: DynamicEnvOptions = { forceLoad: false }
  ) => {
    const load = !options.forceLoad && this._load;
    if (load) {
      return;
    }

    const env: DynamicVariablesType = await fetch(path)
      .then(res => {
        this._load = true;
        return res.json();
      })
      .catch(err => console.error(err));

    this.set(env);
  };
}
