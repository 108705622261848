import { MenuItem } from '@affine/core/modules/app-sidebar/views';
import { useI18n } from '@affine/i18n';
import { useSetAtom } from 'jotai';
import { useCallback } from 'react';

import { useIsAuthorized } from './api';
import { miroAtom } from './model';
import { MiroLogoIcon } from './ui';
import { signin } from './utils';

export const ImportMiro = () => {
  const t = useI18n();

  const setMiroAtom = useSetAtom(miroAtom);
  const isAuthorizedQuery = useIsAuthorized();

  const onClick = useCallback(() => {
    if (!isAuthorizedQuery.data) {
      signin();
    } else {
      setMiroAtom(prev => ({ ...prev, openModal: true }));
    }
  }, [setMiroAtom, isAuthorizedQuery.data]);

  return (
    <MenuItem icon={<MiroLogoIcon />} onClick={onClick}>
      {t['docnboard.miro.import-button']()}
    </MenuItem>
  );
};
