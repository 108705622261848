import {
  importMarkDown,
  MarkdownAdapter,
  ZipTransformer,
} from '@blocksuite/affine/blocks';
import { assertExists } from '@blocksuite/affine/global/utils';
import type { Doc, DocCollection } from '@blocksuite/affine/store';
import { getAssetName, Job } from '@blocksuite/affine/store';
import JSZip from 'jszip';

import { getBaseUrl } from '../modules/cloud';
import { getFileNameFromContentDisposition } from './content-disposition';
import { downloadBlob } from './download-blob';
interface DocxTransformerConverterOptions {
  type: string;
  exp: boolean;
}

interface DocxTransformerOptions {
  exp?: boolean;
}

function createAssetsArchive(
  assetsMap: Map<string, Blob>,
  assetsIds: string[]
) {
  const zip = new JSZip();

  const assets = zip.folder('assets');
  assertExists(assetsMap);

  assetsMap.forEach((blob, id) => {
    if (!assetsIds.includes(id)) return;
    assets?.file(getAssetName(assetsMap, id), blob);
  });

  return zip;
}

const isKeyOptions = (
  key: string
): key is keyof DocxTransformerConverterOptions => {
  return key === 'exp' || key === 'type';
};

export class DocxTransformer {
  static async exportDoc(
    doc: Doc,
    options: DocxTransformerOptions = { exp: false }
  ) {
    const job = new Job({ collection: doc.collection });
    const snapshot = await job.docToSnapshot(doc);
    if (!snapshot) {
      return;
    }

    if (!snapshot) {
      return;
    }

    const adapter = new MarkdownAdapter(job);

    const markdownResult = await adapter.fromDocSnapshot({
      snapshot,
      assets: job.assetsManager,
    });
    let blobPart: Blob;
    const pageTitle = doc.meta?.title || 'Untitled';
    let name: string;
    const contentBlob = new Blob([markdownResult.file], {
      type: 'plain/text',
    });
    if (markdownResult.assetsIds.length > 0) {
      const zip = createAssetsArchive(job.assets, markdownResult.assetsIds);

      zip.file('index.md', contentBlob);

      blobPart = await zip.generateAsync({ type: 'blob' });
      name = `${pageTitle}.zip`;
    } else {
      blobPart = contentBlob;
      name = `${pageTitle}.md`;
    }

    const isZip = name.includes('zip');
    const sourceFile = isZip ? 'index.zip' : 'index.md';

    const file = new File([blobPart], sourceFile);
    const formData = new FormData();

    const optionsConverter: DocxTransformerConverterOptions = {
      type: 'md-to-docx',
      exp: options.exp || false,
    };

    formData.append('file', file);

    Object.keys(optionsConverter).forEach(key => {
      if (isKeyOptions(key)) {
        formData.append(key, String(optionsConverter[key]));
      }
    });

    const blob = await fetch(getBaseUrl() + '/api/v2/services/converter', {
      method: 'POST',
      body: formData,
    }).then(async res => {
      if (!res.ok) {
        throw new Error(res.statusText);
      }
      const buffer = await res.arrayBuffer();
      return new Blob([buffer]);
    });

    if (blob) {
      downloadBlob(blob, `${pageTitle}.docx`);
    }
  }

  static async importDocx(
    docCollection: DocCollection,
    file: File,
    options: DocxTransformerOptions = { exp: false }
  ) {
    const formData = new FormData();
    const optionsConverter: DocxTransformerConverterOptions = {
      type: 'docx-to-md',
      exp: options.exp || false,
    };

    formData.append('file', file);

    Object.keys(optionsConverter).forEach(key => {
      if (isKeyOptions(key)) {
        formData.append(key, String(optionsConverter[key]));
      }
    });

    const result = await fetch(getBaseUrl() + '/api/v2/services/converter', {
      method: 'POST',
      body: formData,
    }).then(async response => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      const contentDisposition = response.headers.get('Content-Disposition');

      if (!contentDisposition) {
        throw new Error('Content-Disposition header is missing');
      }

      const filename = getFileNameFromContentDisposition(contentDisposition);

      if (!filename) {
        throw new Error('Filename is missing');
      }

      const buffer = await response.arrayBuffer();

      return {
        buffer,
        filename: filename,
        extension: filename.split('.').pop(),
      };
    });

    const downloadedFile = new File(
      [new Blob([result.buffer])],
      `${file.name.split('.')[0]}.${result.extension}`
    );
    return await importMarkDown(docCollection, downloadedFile);
  }
}

export class SnapshotTransformer {
  static async exportDoc(doc: Doc) {
    const collection = doc.collection;
    const blob = await ZipTransformer.exportDocs(collection, [doc]);
    const pageTitle = doc.meta?.title || 'Untitled';

    if (blob) {
      downloadBlob(blob, `${pageTitle}.zip`);
    }
  }
}
