import dayjs from 'dayjs';

import type { DatePickerProps } from './types';

export const DATE_MIN = '1970-01-01';
export const DATE_MAX = '2099-12-31';

export const YEAR_MIN = dayjs(DATE_MIN).year();
export const YEAR_MAX = dayjs(DATE_MAX).year();

export const defaultDatePickerProps = {
  format: 'YYYY-MM-DD',
  gapX: 8,
  gapY: 8,
  weekDays: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'].join(','),
  monthNames: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ].join(','),
  todayLabel: 'Today',
  startWeek: 1,
} satisfies Partial<DatePickerProps>;
