import {
  MenuItem,
  MenuTrigger,
  RadioGroup,
  type RadioItem,
  Scrollable,
  Switch,
} from '@affine/component';
import {
  SettingRow,
  SettingWrapper,
} from '@affine/component/setting-components';
import {
  EditorSettingService,
  type FontFamily,
  fontStyleOptions,
} from '@affine/core/modules/editor-settting';
import { useI18n } from '@affine/i18n';
import { useLiveData, useServices } from '@toeverything/infra';
import {
  forwardRef,
  type HTMLAttributes,
  type PropsWithChildren,
  useCallback,
  useMemo,
} from 'react';

import { DropdownMenu } from './menu';
import * as styles from './style.css';

const getLabel = (fontKey: FontFamily, t: ReturnType<typeof useI18n>) => {
  switch (fontKey) {
    case 'Sans':
      return t['com.affine.appearanceSettings.fontStyle.sans']();
    case 'Serif':
      return t['com.affine.appearanceSettings.fontStyle.serif']();
    case 'Mono':
      return t[`com.affine.appearanceSettings.fontStyle.mono`]();
    case 'Custom':
      return t['com.affine.settings.editorSettings.edgeless.custom']();
    default:
      return '';
  }
};

export const getBaseFontStyleOptions = (
  t: ReturnType<typeof useI18n>
): Array<Omit<RadioItem, 'value'> & { value: FontFamily }> => {
  return fontStyleOptions
    .map(({ key, value }) => {
      if (key === 'Custom') {
        return null;
      }
      const label = getLabel(key, t);
      return {
        value: key,
        label,
        testId: 'system-font-style-trigger',
        style: {
          fontFamily: value,
        },
      } satisfies RadioItem;
    })
    .filter(item => item !== null);
};

export const FontFamilySettings = () => {
  const t = useI18n();
  const { editorSettingService } = useServices({ EditorSettingService });
  const settings = useLiveData(editorSettingService.editorSetting.settings$);

  const radioItems = useMemo(() => {
    const items = getBaseFontStyleOptions(t);
    if (!BUILD_CONFIG.isElectron) return items;

    // resolve custom fonts
    const customOption = fontStyleOptions.find(opt => opt.key === 'Custom');
    if (customOption) {
      const fontFamily = settings.customFontFamily
        ? `${settings.customFontFamily}, ${customOption.value}`
        : customOption.value;
      items.push({
        value: customOption.key,
        label: getLabel(customOption.key, t),
        testId: 'system-font-style-trigger',
        style: { fontFamily },
      });
    }

    return items;
  }, [settings.customFontFamily, t]);

  const handleFontFamilyChange = useCallback(
    (value: FontFamily) => {
      editorSettingService.editorSetting.set('fontFamily', value);
    },
    [editorSettingService.editorSetting]
  );

  return (
    <SettingRow
      name={t['com.affine.appearanceSettings.font.title']()}
      desc={t['com.affine.appearanceSettings.font.description']()}
    >
      <RadioGroup
        items={radioItems}
        value={settings.fontFamily}
        width={250}
        className={styles.settingWrapper}
        onChange={handleFontFamilyChange}
      />
    </SettingRow>
  );
};

const Scroller = forwardRef<
  HTMLDivElement,
  PropsWithChildren<HTMLAttributes<HTMLDivElement>>
>(({ children, ...props }, ref) => {
  return (
    <Scrollable.Root>
      <Scrollable.Viewport {...props} ref={ref}>
        {children}
      </Scrollable.Viewport>
      <Scrollable.Scrollbar />
    </Scrollable.Root>
  );
});

Scroller.displayName = 'Scroller';

export const DeFaultCodeBlockSettings = () => {
  const t = useI18n();
  return (
    <>
      <SettingRow
        name={t[
          'com.affine.settings.editorSettings.general.default-code-block.language.title'
        ]()}
        desc={t[
          'com.affine.settings.editorSettings.general.default-code-block.language.description'
        ]()}
      >
        <DropdownMenu
          items={<MenuItem>Plain Text</MenuItem>}
          trigger={
            <MenuTrigger className={styles.menuTrigger} disabled>
              Plain Text
            </MenuTrigger>
          }
        />
      </SettingRow>
      <SettingRow
        name={t[
          'com.affine.settings.editorSettings.general.default-code-block.wrap.title'
        ]()}
        desc={t[
          'com.affine.settings.editorSettings.general.default-code-block.wrap.description'
        ]()}
      >
        <Switch />
      </SettingRow>
    </>
  );
};

export const SpellCheckSettings = () => {
  const t = useI18n();
  return (
    <SettingRow
      name={t['com.affine.settings.editorSettings.general.spell-check.title']()}
      desc={t[
        'com.affine.settings.editorSettings.general.spell-check.description'
      ]()}
    >
      <Switch />
    </SettingRow>
  );
};

export const General = () => {
  const t = useI18n();

  return (
    <SettingWrapper title={t['com.affine.settings.editorSettings.general']()}>
      <FontFamilySettings />
    </SettingWrapper>
  );
};
