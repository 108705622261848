import { useQuery } from '@tanstack/react-query';

import { getBoards, isAuthorized } from './api';

export const useGetBoards = (options?: { enabled?: boolean }) =>
  useQuery({
    queryKey: ['miro', 'boards'],
    queryFn: async () => {
      const result = await getBoards();
      return result.data;
    },
    enabled: options?.enabled,
  });

export const useIsAuthorized = (options?: { enabled?: boolean }) =>
  useQuery({
    queryKey: ['miro', 'authorize'],
    queryFn: async () => {
      const result = await isAuthorized();
      return result.data.isAuthorized;
    },
    enabled: options?.enabled,
  });
