import { useDynamicVariables } from '@affine/core/utils';
import { useEffect } from 'react';

export const YandexMetricProvider = () => {
  const variables = useDynamicVariables();

  useEffect(() => {
    if (!variables?.DNB_YANDEX_METRIC) return;

    const elementScript = document.createElement('script');
    elementScript.innerText = `(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
       m[i].l=1*new Date();
       for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
       k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
       (window, document, "script", "https://mc.webvisor.org/metrika/tag_ww.js", "ym");

       ym("${variables.DNB_YANDEX_METRIC}", "init", {
            clickmap:true,
            trackLinks:true,
            accurateTrackBounce:true,
            webvisor:true,
            trackHash:true
       });`;

    const elementNoScript = document.createElement('noscript');
    elementNoScript.innerText = `<noscript><div><img src="https://mc.yandex.ru/watch/${variables.DNB_YANDEX_METRIC}" style="position:absolute; left:-9999px;" alt="" /></div></noscript>`;

    document.head.append(elementScript);
    document.head.append(elementNoScript);

    return () => {
      elementScript.remove();
      elementNoScript.remove();
    };
  }, [variables]);

  return null;
};
