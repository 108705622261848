import { fetchCloud, getBaseUrl } from '@affine/core/modules/cloud';

import type { Board } from '../model';

export const getBoards = async () => {
  const result = await fetchCloud(`/api/v2/services/miro/boards`);
  return (await result.json()) as { data: Board[] };
};

export const isAuthorized = async () => {
  const result = await fetchCloud(`/api/v2/services/miro/auth`);
  return (await result.json()) as { data: { isAuthorized: boolean } };
};

export const getFileImportBoard = async (board: Board) => {
  const result = await fetchCloud(
    `/api/v2/services/miro/boards/${board.boardId}/snapshot`,
    {
      timeout: Number.MAX_SAFE_INTEGER,
    }
  );
  const buffer = await result.arrayBuffer();
  const blob = new Blob([buffer]);
  const file = new File([blob], board.name + '.zip', {
    type: 'application/zip',
  });

  return file;
};

export const logout = async () => {
  const result = await fetchCloud(`/api/v2/services/miro/auth/logout`, {
    method: 'POST',
  });
  return result.status === 200;
};

export const getUrlLogin = () => {
  return `${getBaseUrl()}/api/v2/services/miro/auth/login`;
};
