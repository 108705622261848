import type dayjs from 'dayjs';

import type { WeekOptions } from './types';

export function isSameDay(a: dayjs.Dayjs, b: dayjs.Dayjs) {
  return a.isValid() && b.isValid() && a.isSame(b, 'day');
}

export function isSameMonth(a: dayjs.Dayjs, b: dayjs.Dayjs) {
  return a.isValid() && b.isValid() && a.isSame(b, 'month');
}

export function getWeekDays(weekdays: string[], options?: WeekOptions) {
  const startWeek = options?.startWeek || 0;
  return weekdays.map((_, index) => weekdays[(index + startWeek) % 7]);
}
